import React from "react"
import { Link } from "gatsby"

import { Layout, Seo, classes } from "../../layout"
import { AvailableAsWebApp, IPhone, img } from "../../components"

export default function IndexPage(props) {
  const { heading, iphone } = classes
  const section = "p-6 py-8 md:px-16 max-w-5xl mx-auto"
  const paragraph = "my-6"

  return (
    <Layout path={props.path}>
      <Seo title="Trailguide White Label App" />

      <div className={section + " mt-24 sm:flex"}>
        <div>
          <h1 className={heading}>Trailguide White Label App</h1>
          <p className={paragraph}>
            We offer custom white-label tour apps for your reosrt or
            destination. Maintain total control of the content you present to
            your guests and visitors having your own tour app.
          </p>
          <p className={paragraph}>
            The information you present to your guests is limited to those
            activities related to your destination only.
          </p>
          <p className={paragraph}>
            The app is a modern web app which enables you as a destination to
            get the proper information into the hands of your guests very
            easily. The only thing they need to do is to scan a QR code or click
            a link.
          </p>
          <p className={paragraph}>
            It only takes 1 second to download and get access to related
            information, digital maps, and GPS navigation.
          </p>
          <p className={paragraph}>
            Even though no installation is required to actually use your app,
            the guests can still install it in seconds by adding it to the home
            screen of their phone.
          </p>
          <br />
          <Link href="https://trysilbikearena.our.guide" target="trysil">
            <AvailableAsWebApp
              url="https://trysilbikearena.our.guide"
              label="trysilbikearena.our.guide"
            />
          </Link>
        </div>
        <div className="hidden md:block">
          <IPhone
            alt="Your own custom tour app"
            src={img("/website/trailguide-iphone-sandbox.jpeg")}
            className={iphone}
          />
        </div>
      </div>

      <div className={section}>
        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Your app will have its own name and icon"
              src={img("/website/trailguide-iphone-sandbox-icons.jpg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Your app will have its own app name and icon and can be installed
              as any other native app on a phone or tablet.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="You can control the visual appearance"
              src={img("/website/trailguide-iphone-sandbox-page.jpg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              In addition to having full control over the content, you can also
              control the look & feel of the app.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Make local services available in the app"
              src={img("/website/trailguide-iphone-sandbox-services.jpg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Make local services such as hotels, restaurants, and shops
              available to your guests directly in your app.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
